import React from "react";
import {
  Box,
  HStack,
  Spacer,
  Touchable,
  Text,
  VStack,
} from "../../Components/UI";
import { Colors } from "../../../Mono/consts/Colors";
import { Icon, IconType } from "../../assets/icons";
import { TextInput } from "../../Components/UX";

interface AddTagsProps {
  tags: string[];
  setTags: (tags: string[]) => void;
  tagField: string;
  setTagField: (tag: string) => void;
  tagOptions: string[];
  isMaxTags: boolean;
}

const TagListItem = ({
  tag,
  onPress,
}: {
  tag: string;
  onPress: () => void;
}) => (
  <Touchable onPress={onPress}>
    <Box
      spacing={{
        left: 20,
        right: 20,
        top: 10,
        bottom: 10,
      }}
    >
      <HStack center>
        <Box center>
          <Icon type={IconType.Add} size={20} />
        </Box>
        <Box key={tag} spacing={10}>
          <Text text={tag} />
        </Box>
      </HStack>
    </Box>
  </Touchable>
);

const TagPill = ({ tag, onPress }: { tag: string; onPress: () => void }) => (
  <Touchable onPress={onPress}>
    <Box key={tag} spacing={5} color={Colors.Black2} borderRadius={15}>
      <Box
        spacing={{
          left: 7,
          right: 7,
          top: 9,
          bottom: 9,
        }}
        style={{ padding: 8 }}
      >
        <HStack center>
          <Icon type={IconType.X} size={20} />
          <Spacer x={5} />
          <Text text={tag} />
        </HStack>
      </Box>
    </Box>
  </Touchable>
);

export const AddTagsForm: React.FC<AddTagsProps> = ({
  tags,
  setTags,
  tagField,
  setTagField,
  tagOptions,
  isMaxTags,
}) => {
  return (
    <>
      {!isMaxTags ? (
        <TextInput
          placeholder="タグを追加"
          value={tagField}
          onChange={setTagField}
          style={{ backgroundColor: Colors.Black3, fontSize: 20, margin: 10 }}
        />
      ) : null}
      <Spacer y={0} />
      <Box spacing={{ left: 5, right: 5 }}>
        <HStack
          style={{
            flexWrap: "wrap",
          }}
        >
          {tags.map((tag) => (
            <TagPill
              tag={tag}
              onPress={() => setTags(tags.filter((t) => t !== tag))}
            />
          ))}
        </HStack>
      </Box>
      <Text styles={{ color: Colors.Grey, margin: 10 }}>
        {tags.length || 0} / 6
      </Text>
      <Box
        style={{
          overflow: "scroll",
          maxHeight: 300,
        }}
      >
        <VStack>
          {!isMaxTags &&
            tagOptions.map((item) => (
              <TagListItem
                tag={item}
                onPress={() => {
                  setTags([...tags, item]);
                }}
              />
            ))}
        </VStack>
      </Box>
    </>
  );
};
