import React from "react";
import { Masonry } from "react-plock";
import { Box } from "../../Components/UI";
import { MasonryPostCard } from "./MasonryPostCard";
import { Post } from "../../../Mono/types";

export const MasonryComponent = ({
  posts,
  onClick,
}: {
  posts: Post[];
  onClick: (post: Post) => void;
}) => {
  return (
    <>
      <Box center>
        <Box>
          <Masonry
            items={posts}
            config={{
              columns: [2, 3, 4, 4],
              gap: [12, 12, 12, 12],
              media: [640, 968, 1024, 1200],
            }}
            render={(item) => (
              <MasonryPostCard
                key={String(item.id)}
                post={item}
                onClick={() => onClick(item)}
              />
            )}
          />
        </Box>
      </Box>
    </>
  );
};
